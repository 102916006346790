// all overwrites here
// .scrolled .icon-bar{
// 	background-color: #2778BC;
// }
@import './variables.scss';
@import "bootstrap/scss/mixins/_breakpoints";

@each $height in map-keys($line-heights) {
  .lh-#{$height} {
    $pixel : unquote("px");
    line-height: #{$height}$pixel;
  }
}

@each $size in map-keys($font-sizes) {
  .fs-#{$size} {
    $pixel : unquote("px");
    font-size: #{$size}$pixel;
  }
}


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .w-#{$breakpoint}-100 {
      width:100% !important;
    }
    .w-#{$breakpoint}-100 {
      width:100% !important;
    }
    .w-#{$breakpoint}-100 {
      width:100% !important;
    }
    .w-#{$breakpoint}-100 {
      width:100% !important;
    }
    .w-#{$breakpoint}-100 {
      width:100% !important;
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .w-#{$breakpoint}-75 {
      width:100% !important;
    }
    .w-#{$breakpoint}-75 {
      width:100% !important;
    }
    .w-#{$breakpoint}-75 {
      width:100% !important;
    }
    .w-#{$breakpoint}-75 {
      width:100% !important;
    }
    .w-#{$breakpoint}-75 {
      width:100% !important;
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .w-#{$breakpoint}-50 {
      width:100% !important;
    }
    .w-#{$breakpoint}-50 {
      width:100% !important;
    }
    .w-#{$breakpoint}-50 {
      width:100% !important;
    }
    .w-#{$breakpoint}-50 {
      width:100% !important;
    }
    .w-#{$breakpoint}-50 {
      width:100% !important;
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .w-#{$breakpoint}-25 {
      width:100% !important;
    }
    .w-#{$breakpoint}-25 {
      width:100% !important;
    }
    .w-#{$breakpoint}-25 {
      width:100% !important;
    }
    .w-#{$breakpoint}-25 {
      width:100% !important;
    }
    .w-#{$breakpoint}-25 {
      width:100% !important;
    }
  }
}