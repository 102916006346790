@import './theme/variables.scss';
@import "bootstrap/scss/mixins/_breakpoints";

.icon-bar {
	width: 22px; 
	height: 2px;
	background-color: #FFF;
	display: block;
	transition: all 0.2s;
	margin-top: 4px
}
 
.navbar-toggler {
  border: none;
  background: transparent !important;
}

 
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
 
.navbar-toggler .middle-bar {
  opacity: 0;
}
 
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
 
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
 
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
 
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
.btn-outline-light:hover{
    color: #212529 !important;
}
.scrolled .btn-outline-light-scroll:hover{
    background-color: #FFF !important;
    color:#212529 !important;
}
.chevron-down {
    padding: .2rem .3rem;
}
.icon-width {
	width: 127px;
	height: 127px;
    border-radius: 50%;
    vertical-align: middle;
    padding: 2rem 1rem;
}

.feature-icon{
    width: 49px;
    height: 44px;
    object-fit: contain;
}
.selectable{
    cursor: pointer;
}

nav{
    transition:.2s ease-in-out;
    background-color: transparent;
}
nav.scrolled{
    background-color:#3f3f53;
    transition:.2s ease-in-out;
}
.scrolled .btn-outline-light-scroll{
    border:1px solid #fff;
    color: #FFF;
}
.opacity-0 {
    opacity:0!important;
}
.opacity-1 {
    opacity:0.2!important;
}
.opacity-2 {
    opacity:0.4!important;
}
.opacity-3 {
    opacity:0.6!important;
}
.opacity-4 {
    opacity:.8!important;
}
.opacity-5 {
    opacity:1!important;
}
.bg-primary-light{
    background-color:rgba(86, 177, 209, .2);   
}
.text-dark-blue{
    color:#3F3F53;
}
.resize-none{
    resize:none;
}
.btn{
    min-width: 145px;
    height: 43px;
    line-height: 30px;
}
.left-0{
    left:0;
}
.right-0{
    right:0;
}
.company-header{
    background-image:url(../img/athena_logo@2x.png);
    background-size: 30%;
    background-position: center;
    background-repeat: no-repeat;
    min-height:540px;
    @include media-breakpoint-down(md) { 
        min-height:340px;
        background-size: 50%;
    }
}

#globalImpact {
    @include media-breakpoint-down(md) { 
        background-position-x: -200px !important;
        background-position-y: 200px !important;
        margin-bottom:-57px !important;
    }
}

.jumbotron-hero-image-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height:55vh;
}