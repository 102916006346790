@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');

$theme-colors: (
  "primary":    #56B1D1,
  "success":    #139046,
  "secondary":  #2778BC,
  "info":       #e3b826,
  "warning":    #e39647,
  "danger":     #a3490f,
  "light":      #fff,
  "dark":       #000,
  "light-green": rgba(74, 167, 112, 0.59)
);

// fonts
$font-family-sans-serif: "Lato", sans-serif !default;
$font-size-base: 1rem !default;

$line-heights:(
  24:24px,
  29:29px
);

$font-sizes:(
  12:12px,
  18:18px,
  20:20px,
  24:24px,
  36:36px,
  45:45px
);

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// container sizes
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;